import { call, put, takeLatest } from "redux-saga/effects";
import {
  MAINTENANCE_ERROR_DATA,
  recieveMaintenanceErrorData,
} from "../actions/maintenanceAction";
import { fetchMaintenanceData } from "./api/maintenanceApi";

//Calling maintenance contentful data api
export function* getMaintenanceData(action) {
  try {
    const data = yield call(fetchMaintenanceData, action.data);
    yield put(recieveMaintenanceErrorData(data));
  } catch (e) {
    yield put(recieveMaintenanceErrorData("error"));
  }
}

// Maintenance Error Screen Saga
export function* MaintenanceErrorScreenSaga() {
  yield takeLatest(MAINTENANCE_ERROR_DATA, getMaintenanceData);
}
