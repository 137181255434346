// Declaring actions type
export const FORM_DATA = "FORM_DATA";
export const RECEIVE_FORM_DATA = "RECEIVE_FORM_DATA";

// Declaring actions
export const requestFormData = (data) => ({
  type: FORM_DATA,
  data,
});
export const recieveFormData = (data) => ({
  type: RECEIVE_FORM_DATA,
  data,
});
