const query = `query ($langid: String, $preview: Boolean) {
  standardPageCollection(limit: 1, locale: $langid, where: {slug: "broker-payment-error-page"}, preview: $preview) {
    items {
      slug
      seoMetadata(locale: $langid, preview: $preview) {
        title
        description
        noIndex
        noFollow
        canonicalUrl
      }
      componentsCollection(locale: $langid, preview: $preview) {
        items {
          componentName: __typename
          ... on ErrorBanner {
            errorType
            primaryImg {
              url
              width
              height
            }
            primaryImgAltText
            title
            description {
              json
            }
            cta1Title
            cta1Link
            cta2Title
            cta2Link
          }
        }
      }
    }
  }
}`;

export default query;
