const query = `query ($langid: String, $preview: Boolean) {
    validationMessageGroupCollection(limit: 1, locale: $langid, where: {slug: "broker-payment-validation-message-group"}, preview: $preview) {
      items {
        slug
        messageCollection(locale: $langid, preview: $preview) {
          items {
            type
            key
            value
          }
        }
        sys {
          spaceId
          publishedAt
          publishedVersion
        }
      }
    }
  }
  `;

export default query;
