import Modal from "react-bootstrap/Modal";
import "../assets/styles/components/browserNotSupported.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { connect } from "react-redux";

const BrowserNotSupported = (props) => {
  //to close the browser not suppported pop on click of cross button
  const handleClose = () => {
    props.setbrowserSupport(false);
  };

  // to map labels from contentfull data
  var getAriaLabel = {};
  props?.k?.contentCollection?.items[0].ariaLabelsCollection.items?.map(
    (i) => (getAriaLabel[i.labelName] = i.labelText)
  );

  return (
    <Modal
      size="lg"
      show={props.support}
      role="dialog"
      aria-hidden="false"
      aria-label={getAriaLabel["browser_support_dialong_window"]}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.contentfulData.value} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* rendering rich text from contentful Data */}
        {documentToReactComponents(props.contentfulData.popupMessage.json)}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  k: state.navigationData,
});

export default connect(mapStateToProps)(BrowserNotSupported);
export { BrowserNotSupported, mapStateToProps };
