import { RECEIVE_MAINTENANCE_ERROR_DATA } from "../actions/maintenanceAction";

const maintenanceReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_MAINTENANCE_ERROR_DATA:
      return data;
    default:
      return state;
  }
};
export default maintenanceReducer;
