import React, {useEffect} from "react";
import "../assets/styles/components/creditcardPayments.scss";
import axios from "axios";
import {ROUTE} from "../constants/enums";
import {connect} from "react-redux";
import cross from "../assets/images/cross.svg";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS} from "@contentful/rich-text-types";
import PropTypes from "prop-types";
import Spinner from "../common/spinner";

const mapStateToProps = (state) => ({k: state.formData});

const CreditPayment = (props) => {
    // redirecting to 404 page if directly accessed using url
    // if (
    //   props?.history?.location?.state === undefined ||
    //   props?.history?.location?.state?.policyNumber === undefined
    // ) {
    //   props.history.push("404");
    // }

    // setting refs and state variables
    var Eref = React.useRef();
    var tooltipRef = React.useRef();
    const [error, setError] = React.useState([]);
    const [loader, setloader] = React.useState(true);
    const [policyDetails, setpolicyDetails] = React.useState();

    // mapping error messages from contentful
    var getError = {};
    props?.k?.messagesCollection?.items.forEach((error) => {
        getError[error.key] = error.value;
    });

    // mapping labels from contentful
    var getLabel = {};
    props?.k?.ariaLabelsCollection?.items?.map(
        (i) => (getLabel[i.labelName] = i.labelText)
    );

    // To focus on error messages if found during validation
    React.useEffect(() => {
        if (error.length > 0) {
            Eref.current.childNodes[0].childNodes[0].focus();
        }
    }, [error]);

    React.useEffect(() => {
        const getPolicyDetails = async (destroy) => {
            try {
                const restoken = await axios(process.env.REACT_APP_AUTH);
                const res = await axios.post(process.env.REACT_APP_GET_POLICY_DETAILS, {
                    _csrf: restoken.data.csrfToken,
                    destroy,
                });
                if (res.status === 200) {
                    setpolicyDetails(res.data.data);
                    setloader(false);
                }
            } catch (error) {
                props.history.push(ROUTE.INTERNAL_ERROR, {state: "500"});
            }
        };

        if (props.validNavigation || performance.navigation.type === 1) {
            props.setValidNavigation(false);
            getPolicyDetails(false);
        } else {
            getPolicyDetails(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history]);

    useEffect(() => {
        if (policyDetails) {
            setTimeout(() => {
                var ele = document?.getElementById("submit-button");
                if (ele) ele.disabled = false;
            }, 1000);
        } else {
            setTimeout(() => {
                var ele = document?.getElementById("submit-button");
                if (ele) ele.disabled = true;
            }, 1000);
        }
    }, [policyDetails]);

    // To remove previously filled credit card information on clicking back button in mozila browser
    React.useEffect(() => {
        if (window.addEventListener) {
            window.addEventListener("message", respMsg, false);

            window.addEventListener("pageshow", function (event) {
                var historyTraversal =
                    event.persisted ||
                    (typeof window.performance != "undefined" &&
                        window.performance.navigation.type === 2);
                if (historyTraversal) {
                    setloader(true);

                    setloader(false);
                }
            });
        } else {
            if (window.attachEvent) {
                window.attachEvent("onmessage", respMsg);
            }
        }
        return () => {
            window.removeEventListener("message", respMsg);
        };
    });

    // Display internal server error page if we dont get the data from contentful
    if (props.k === "error") {
        props.history.push(ROUTE.INTERNAL_ERROR, {state: "500"});
        return <Spinner/>;
    }

    // to map labels from contentful data
    var getValue = {};
    props?.k?.labelsCollection?.items?.map(
        (i) => (getValue[i.labelName] = i.labelText)
    );

    // to map image icons from contentful data
    var getImg = {};
    props?.k?.componentsCollection?.items[1]?.iconImageCollection.items?.map(
        (i) => (getImg[i.key] = i.icon.url)
    );

    // to map error messages from contentfull data
    var getMessage = {};
    props?.k?.messagesCollection?.items.map((i) => (getMessage[i.key] = i.value));

    //Defining error messages for respective error codes returned from moneris
    const errorCode = {
        940: {msg: getMessage["ERR_940"]},

        941: {msg: getMessage["ERR_941"]},

        942: {msg: getMessage["ERR_942"]},

        943: {msg: getMessage["ERR_943"]},

        944: {
            msg: getMessage["ERR_944"],
        },

        945: {
            msg: getMessage["ERR_945"],
        },
    };

    // Function to call make payment api after we recieve response from moneris on submit
    var respMsg = async function (e) {
        // Converting moneris response from string to object if the reponse is in string
        try {
            var respData = e.data;
            if (typeof respData === "string") {
                respData = JSON.parse(respData);
            }

            // Block to execute if datakey is present with no error codes from moneris response
            if (respData.dataKey) {
                setloader(true);
                setError([]);
                makePayment();

                async function makePayment() {
                    var restoken;
                    try {
                        // Fetching the auth token for make payment api call
                        restoken = await axios(process.env.REACT_APP_AUTH);
                    } catch (e) {
                        // Redirecting to internal server page if we dont recieve auth token
                        props.history.push(ROUTE.INTERNAL_ERROR, {state: "500"});
                        return;
                    }

                    // Make payment api call
                    axios
                        .post(process.env.REACT_APP_MAKE_PAYMENT, {
                            _csrf: restoken.data.csrfToken,

                            Payment: {
                                token: respData.dataKey,
                            },
                        })
                        .then((response) => {
                            setloader(false);

                            // Displaying payment success page if payment is successfull
                            if (response.data.data.jsonData.ResponseStatus.StatusCode === 1) {
                                props.history.push(ROUTE.SUCCESS, {
                                    transactionNumber:
                                    response.data.data.jsonData.BrokerPaymentResponse
                                        .TransactionNumber,
                                    resData: response.data.data.jsonData,
                                    policyDetails: policyDetails,
                                    policyNumber: policyDetails
                                        ? policyDetails?.policyNumber?.toUpperCase()
                                        : ""

                                });
                                console.log('sar', props.history);
                            } else if (
                                // Displaying payment failure page if payment is failed
                                response.data.data.jsonData.ResponseStatus.StatusCode === 0
                            ) {
                                // Displaying payment failure page if any error occurs during the make payment api call
                                props.history.push({
                                    pathname: ROUTE.FAIL,
                                    state:
                                        getError[
                                            response?.data?.data?.jsonData?.ResponseStatus
                                                ?.Messages[0]?.MessageCode
                                            ],
                                });
                            } else {
                                // Displaying payment failure page if any error occurs during the make payment api call
                                props.history.push({
                                    pathname: ROUTE.FAIL,
                                    state: "500",
                                });
                            }
                        })
                        .catch((err) => {
                            // Displaying payment failure page if we recieve 500 response code from the makepayment api call
                            setloader(false);
                            props.history.push({
                                pathname: ROUTE.FAIL,
                                state: "500",
                            });
                        });
                }
            } else {
                // To set the feild level error messages if we receive error codes from moneris
                const array = respData.responseCode;
                if (array) {
                    array.sort();
                    const err = array.map((val) => errorCode[val.toString()]);
                    setError(err);
                }
            }
        } catch (e) {
            // Display internal server error page if any error occurs
            props.history.push(ROUTE.INTERNAL_ERROR, {state: "500"});
        }
    };

    // To send the credit card information to moneris on click of submit button
    const doMonerisSubmit = () => {
        var monFrameRef = document.getElementById("monerisFrame").contentWindow;

        if (monFrameRef && policyDetails) {
            monFrameRef.postMessage("tokenize", process.env.REACT_APP_MONERIS_URL);
        }

        return false;
    };

    // To open "what is cvv" tooltip
    const showTooptip = () => {
        tooltipRef.current.classList.add("show");
    };

    // To hide "what is cvv" tooltip
    const hideTooptip = () => {
        tooltipRef.current.classList.remove("show");
    };

    // Setting images recieved from contentful rich text
    function renderOptions(links) {
        const assetMap = new Map();
        for (const asset of links.assets.block) {
            assetMap.set(asset.sys.id, asset);
        }

        return {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                    const asset = assetMap.get(node.data.target.sys.id);
                    return <img src={asset.url} height="35px" alt=""/>;
                },
            },
        };
    }

    // To display loader on make payment api call
    if (loader) {
        return <Spinner/>;
    }

    return (
        <>
            <div id="credit-card-payment" className="credit-card-details-container">
                {/* displaying page level error message after recieving error codes from moneris */}
                {error.length !== 0 ? (
                    <div className="try-again">
                        {getMessage["broker_payment_error_message"]}
                    </div>
                ) : null}

                <div className="credit-card-container-styling">
                    <div className="credit-card-container-details">
                        <h1 className="credit-card-payment-text">
                            {getLabel["credit_card_payment"]}
                        </h1>
                        <h2 className="credit-card-heading">
                            {props.k.componentsCollection.items[1].headingText}
                        </h2>
                        <div className="credit-cards-container">
                            <img
                                src={getImg["broker_payment_visa_icon"]}
                                alt=""
                                height="30px"
                            />
                            <img
                                src={getImg["broker_payment_visa_debit_icon"]}
                                alt=""
                                height="30px"
                            />
                            <img
                                src={getImg["broker_payment_mastercard_icon"]}
                                alt=""
                                height="30px"
                            />
                            <img
                                src={getImg["broker_payment_amex_icon"]}
                                alt=""
                                height="30px"
                            />
                        </div>
                        {/* displaying field level error messages after recieving error codes from moneris */}
                        {error.length !== 0 ? (
                            <ul
                                className="error"
                                id="page_error_block"
                                ref={Eref}
                                tabIndex="-1"
                                aria-atomic="true"
                                aria-live="assertive"
                            >
                                {error.map((value, index) => (
                                    <li key={index}>
                                        <span>{` ${value.msg}`}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                        {/* Displaying moneris iframe for entering credit card details */}
                        <iframe
                            id="monerisFrame"
                            src={
                                `${process.env.REACT_APP_MONERIS_URL}?id=${process.env.REACT_APP_MONERIS_SPACEID}&enable_exp=1&enable_cvd=1&display_labels=1&css_textbox=margin-left:%202px;margin-right:%206px;display:%20block;background-color:%20%23fff;border:%201px%20solid%20%23e5e5e5;color:%20%23333;border-radius:%203px;height:%2040px;padding:%200%2010px;&css_input_label=font-family:%20Avenir%20LT%20W01_55%20Roman1475520,Helvetica%20Neue,Helvetica,Arial,sans-serif;font-style:%20normal;font-weight:%20normal;display:%20block;margin:%2015%200%206%200;color:%20%00338d;font-size:%2016px;box-sizing:%20border-box;&css_textbox_pan=width:80%25;&css_textbox_cvd=width:80%25;&css_textbox_exp=width:80%25;&exp_label=Expiry%20date%20(MMYY):&cvd_label=CVV:&pan_label=Credit%20card%20number:`
                            }
                            className="moneris-iframe"
                            frameBorder="0"
                            scrolling="no"
                            title={props.k.componentsCollection.items[1].headingText}
                            name="monerisFrame"
                        ></iframe>

                        {/* To display 'what is cvv' tooltip on focus or hover on the "what is cvv" text  */}
                        <div className="about-cvv" onMouseLeave={hideTooptip}>
              <span
                  tabIndex="0"
                  className="about-cvv-text"
                  onKeyDown={(e) => {
                      if (e.key === "Enter") {
                          showTooptip();
                      }
                  }}
                  onMouseOver={() => {
                      showTooptip();
                  }}
              >
                {props.k.componentsCollection.items[1].toolTip.linkTitle}
              </span>
                            <div className="cvv-tooltip" ref={tooltipRef}>
                                {documentToReactComponents(
                                    props.k.componentsCollection.items[1].toolTip.toolTip.json,
                                    renderOptions(
                                        props.k.componentsCollection.items[1].toolTip.toolTip.links
                                    )
                                )}
                                {/* "what is cvv" close button */}
                                <span
                                    className="cvv-tooltip-close"
                                    tabIndex="0"
                                    onClick={hideTooptip}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            hideTooptip();
                                        }
                                    }}
                                >
                  <img src={cross} alt="close"/>
                </span>
                            </div>
                        </div>
                    </div>
                    <div className="credit-card-payment-summary">
                        <h2 className="credit-card-payment-heading">
                            {props.k.componentsCollection.items[2].headingText}
                        </h2>
                        <div className="credit-card-payment-details">
                            <div className="credit-card-payment-header">
                                {getValue["label_name_broker_payment_policy_number"]}
                            </div>
                            <div className="credit-card-payment-description">
                                {policyDetails
                                    ? policyDetails?.policyNumber?.toUpperCase()
                                    : ""}
                            </div>
                        </div>
                        <div className="credit-card-payment-details credit-card-margin">
                            <div className="credit-card-payment-header ">
                                {getValue["broker_payment_summary_Insurance_premium_total"]}
                            </div>
                            <div className="credit-card-payment-description">
                                {policyDetails ? policyDetails?.policyAmount : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="credit-card-button-container">
                    {/* Previous button */}
                    <div className="credit-card-previous-button">
                        <a
                            id="previous-button"
                            href="# "
                            onClick={(e) => {
                                e.preventDefault();
                                props.setValidNavigation(true);
                                props.history.push(ROUTE.HOME, {getData: true});
                            }}
                        >
                            {props.k.componentsCollection.items[1].cta1.linkTitle}
                        </a>
                    </div>
                    {/* Submit button  */}
                    <button
                        className="credit-card-submit-button"
                        onClick={doMonerisSubmit}
                        id="submit-button"
                    >
                        {props.k.componentsCollection.items[1].cta2.linkTitle}
                    </button>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(CreditPayment);

CreditPayment.propTypes = {
    k: PropTypes.object.isRequired,
};

export {CreditPayment, mapStateToProps};
