import React, { useState } from "react";
import axios from "axios";
import "../assets/styles/components/healthcheck.scss"

const Healthcheck = () => {
  const [resp, setresp] = useState();

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_HEALTHCHECK)
      .then((res) => {
        setresp(JSON.stringify(res.data));
      })
      .catch((err) => {
        setresp(JSON.stringify(err.response.data));
      });
  }, []);

  return <div className="healthcheck" style={{position:"absolute"}}>{resp}</div>;
};

export default Healthcheck;
