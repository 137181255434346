import { call, put, takeLatest } from "redux-saga/effects";
import { ERROR_DATA, recieveErrorData } from "../actions/errorScreenAction";
import { fetchData } from "./api/errorApi";

// Calling error screen data api
export function* getApiData(action) {
  try {
    const data = yield call(fetchData, action.data);
    yield put(recieveErrorData(data));
  } catch (e) {
    yield put(recieveErrorData("error"));
  }
}

// Error screen saga
export function* errorScreenSaga() {
  yield takeLatest(ERROR_DATA, getApiData);
}
