import { Helmet } from "react-helmet";

const seoMetadata = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props?.seo?.title}</title>
      <meta name="description" content={props?.seo?.description} />
      <meta
        name="robots"
        content={
          props.seo.noIndex === true ? "noindex,nofollow" : "index,follow"
        }
      />
      <link rel="canonical" href={props?.seo?.canonicalUrl} />
    </Helmet>
  );
};

export default seoMetadata;
