import { navigationSaga } from "./navigationSaga";
import { formSaga } from "./formSaga";
import { validationSaga } from "./validationSaga";
import { fork } from "redux-saga/effects";
import { errorScreenSaga } from "./errorScreenSaga";
import { MaintenanceErrorScreenSaga } from "./maintenanceSaga";

// Root saga
export default function* rootSaga() {
  yield fork(formSaga);
  yield fork(navigationSaga);
  yield fork(validationSaga);
  yield fork(errorScreenSaga);
  yield fork(MaintenanceErrorScreenSaga);
}
