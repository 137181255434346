import React from "react";
import axios from "axios";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // Function to send error to server log
  sendLog = async (error, errorInfo) => {
    try {
      const restoken = await axios(process.env.REACT_APP_AUTH);
      axios.post(process.env.REACT_APP_UILOGS, {
        _csrf: restoken.data.csrfToken,
        data: { error: error.toString(), errorInfo },
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidCatch(error, errorInfo) {
    // Sending the error log to the server
    this.sendLog(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render the custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
