import { RECEIVE_FORM_DATA } from "../actions/formAction";

const formReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_FORM_DATA:
      return data;
    default:
      return state;
  }
};
export default formReducer;
