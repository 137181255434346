// Declaring actions type
export const VALIDATION_DATA = "VALIDATION_DATA";
export const RECEIVE_VALIDATION_DATA = "RECEIVE_VALIDATION_DATA";

// Declaring actions type
export const requestValidationData = (data) => ({
  type: VALIDATION_DATA,
  data,
});
export const recieveValidationData = (data) => ({
  type: RECEIVE_VALIDATION_DATA,
  data,
});
