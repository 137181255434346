import React from "react";
import { withRouter } from "react-router";
import MaintenancePage from "./common/maintenance";

const MaintenanceRoute = ({ history }) => {
  return (
    <React.Fragment>
      <MaintenancePage history={history} />
    </React.Fragment>
  );
};

export default withRouter(MaintenanceRoute);
