export const POLICY_TYPE = {
  AUTOMOBILE: "Automobile",
  PROPERTY: "Property",
};

export const ROUTE = {
  SUCCESS: "/paymentsuccess",
  HOME: "/",
  FAIL: "/paymentfailed",
  PAYMENT: "/creditcardpayment",
  ERROR: "/401",
  INTERNAL_ERROR: "/500",
  MAINTENANCE_ERROR: "/Maintenance",
  PAGE_NOT_FOUND: "/404",
  HEALTHCHECK:"/healthcheck"
};
export const LANGID = {
  ENGLISH: "en-US",
  FRENCH: "fr-CA",
};
