import { RECEIVE_ERROR_DATA } from "../actions/errorScreenAction";

const errorScreenReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ERROR_DATA:
      return data;
    default:
      return state;
  }
};
export default errorScreenReducer;
