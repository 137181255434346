import React from "react";
import "../assets/styles/components/footer.scss";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import {useHistory} from "react-router-dom"
import {ROUTE} from "../constants/enums"

// mapping links recieved from conentful rich text
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next) => {
      return (
        <a href={node.data.uri} rel="noreferrer" target="_blank">
          {node.content[0].value}
        </a>
      );
    },
  },
};

const Footer = (props) => {
  const history=useHistory()
  console.log(history)
if(history.location.pathname===ROUTE.HEALTHCHECK) return null
  return (
    <footer id="footer" role="contentinfo">
      <div className="footer-main-section" id="footer-main-section">
        {/* displaying footer links */}
        {props.contentfulData.footerMainSectionsCollection.items.map(
          (link, i) => {
            return (
              <a
                key={i}
                rel="noreferrer"
                target="_blank"
                href={link.sectionLink}
              >
                {link.sectionTitle}
              </a>
            );
          }
        )}
      </div>
      {/* rendering rich text from contentful  */}
      <div className="copyright-text">
        {documentToReactComponents(
          props.contentfulData.legalCopy.json,
          options
        )}
      </div>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  contentfulData: PropTypes.object.isRequired,
};
