import { RECEIVE_VALIDATION_DATA } from "../actions/validationAction";

const validationReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_VALIDATION_DATA:
      return data;
    default:
      return state;
  }
};
export default validationReducer;
