const query = `query ($langid: String, $preview: Boolean) {
  standardPageCollection(limit: 1, locale: $langid, where: {slug: "broker-payment-page"}, preview: $preview) {
    items {
      slug
      seoMetadata(locale: $langid, preview: $preview) {
        title
        description
        noIndex
        noFollow
        canonicalUrl
      }
      componentsCollection(limit: 6, locale: $langid, preview: $preview) {
        items {
          ... on ContentBlock {
            headingText
            title
            subTitle
            description
            backGroundImg {
              title
              url
            }
            iconImageCollection {
              items {
                key
                icon {
                  title
                  url
                }
              }
            }
            toolTip {
              linkTitle
              linkUrl
              toolTip {
                json
                links {
                  assets {
                    block {
                      sys {
                        id
                      }
                      ... on Asset {
                        url
                      }
                    }
                  }
                }
              }
            }
            cta1 {
              linkTitle
              linkUrl
            }
            cta2 {
              linkTitle
              linkUrl
            }
          }
        }
      }
      labelsCollection(locale: $langid, preview: $preview) {
        items {
          labelName
          labelText
        }
      }
      ariaLabelsCollection(locale: $langid, preview: $preview) {
        items {
          labelName
          labelText
        }
      }
      messagesCollection(locale: $langid, preview: $preview) {
        items {
          type
          key
          value
        }
      }
      sys {
        spaceId
        environmentId
        publishedAt
        publishedVersion
        firstPublishedAt
      }
    }
  }
}`;

export default query;
