// Declaring actions type
export const NAVIGATION_DATA = "NAVIGATION_DATA";
export const RECEIVE_NAVIGATION_DATA = "RECEIVE_NAVIGATION_DATA";

// Declaring actions
export const requestNavigationData = (data) => ({
  type: NAVIGATION_DATA,
  data,
});
export const recieveNavigationData = (data) => ({
  type: RECEIVE_NAVIGATION_DATA,
  data,
});
