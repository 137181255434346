import Loading from "../assets/images/Loading.gif";
import { Row, Image } from "react-bootstrap";
import React from "react";
import { connect } from "react-redux";

class Spinner extends React.Component {
  // Changing aria-busy to false while removing the loader
  componentWillUnmount() {
    document.getElementById("loader").ariaBusy = false;
  }

  render() {
    // Mapping labels from contentful data
    var getLabel = {};
    this.props?.k?.contentCollection?.items[0].labelsCollection.items?.map(
      (i) => (getLabel[i.labelName] = i.labelText)
    );

    return (
      <Row aria-busy="true" role="alert" id="loader">
        <Image
          className="spinner-landing"
          id="spinner"
          src={Loading}
          alt={getLabel["loading_content"]}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  k: state.data,
});
export default connect(mapStateToProps)(Spinner);
