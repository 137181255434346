import React from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // scrolls to top of the page
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export default ScrollToTop;
