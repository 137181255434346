import React, {useRef} from "react";
import "../assets/styles/components/successForm.scss";
import {ROUTE} from "../constants/enums";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../common/spinner";
import ReactToPrint from "react-to-print";
import {PrintReceipt} from "./printReceipt";

const mapStateToProps = (state) => ({k: state.formData});

const SuccessMessageComponent = (props) => {
    const componentRef = useRef();
    // Redirecting to 404 page if directly access using url
    if (props.history.location.state === undefined) {
        props.history.push("404");
    }

    // Redirecting to internal server error page for contentful error
    if (props.k === "error") {
        props.history.push(ROUTE.INTERNAL_ERROR, {state: "500"});
        return <Spinner/>;
    }

    // Redirecting to internal server error page for contentful error
    let getImg = {};
    let aria = {};

    props?.k?.componentsCollection?.items[3]?.iconImageCollection.items?.map(
        (i) => (getImg[i.key] = i.icon.url)
    );

    // mapping images from contentfull data
    let getLabel = {};
    props?.k?.ariaLabelsCollection?.items?.map(
        (i) => (getLabel[i.labelName] = i.labelText)
    );
    const paymentResponse = props?.history.location.state?.resData?.BrokerPaymentResponse;
    const dateFormat = paymentResponse.TransactionDateTime.replace("GMT-5", "EDT");
    return (
        <div id="payment-success-screen" role="alert"
             aria-label={aria["payment_successful"]} className="payment-success">
            <div className="print-rec">
                <PrintReceipt
                    amount={paymentResponse?.TransactionAmount}
                    cardNumber={paymentResponse?.MaskedCreditCard}
                    date={dateFormat}
                    policyNumber={props?.history.location.state?.policyDetails?.policyNumber?.toUpperCase()}
                    customerName={props?.history.location.state?.policyDetails?.firstName +
                    ' ' +
                    props?.history.location.state?.policyDetails?.lastName}
                    transactionId={paymentResponse?.TransactionNumber}
                    orderId={paymentResponse?.OrderID}
                    authorizationCode={paymentResponse?.AuthorizationCode}
                    cardType={paymentResponse?.CreditCardType}
                    ref={componentRef}
                />
            </div>
            <h1 className="payment-success-text">{getLabel["payment_successful"]}</h1>
            <div className="image-container">
                <img src={getImg["thumbs_up_icon"]} alt="payment success icon"/>
            </div>
            <h2 className="payment-heading"> {props.k.componentsCollection.items[3].headingText}</h2>

            <div className="tab-container" id="printingContents">
                <div className="row-container">
                    <div className="value">
                        {getLabel["pym_success_payment_type"]}
                    </div>
                    <div className="value"> Credit Card</div>
                </div>

                <div className="row-container">
                    <div className="value">
                        {getLabel["pym_success_txn_date_time"]}
                    </div>
                    <div className="value">{" "}
                        {dateFormat}
                    </div>
                </div>

                <div className="row-container">
                    <div className="value">
                        {getLabel["pym_success_card_number"]}{" "}
                    </div>
                    <div className="value">
                        {paymentResponse?.MaskedCreditCard}
                    </div>
                </div>

                <div className="row-container">
                    <div className="value">
                        {getLabel["pym_success_amount_paid"]}{" "}
                    </div>
                    <div className="value">
                        $ {paymentResponse?.TransactionAmount}
                    </div>
                </div>

                <div className="row-container">
                    <div className="value">
                        {getLabel["pym_success_transaction_id"]}{" "}
                    </div>
                    <div className="value">
                        {paymentResponse?.TransactionNumber}
                    </div>
                </div>
            </div>

            <div className="button-container">
                <button
                    className="pay-other-bills"
                    id="pay-other-bills"
                    onClick={() => {
                        props.history.push(
                            ROUTE.HOME
                        );
                    }}
                >
                    {props.k.componentsCollection.items[3].cta1.linkTitle}
                </button>
                <ReactToPrint
                    trigger={() => (
                        <button id="print-reciept-button" className="print-reciept-button">
                            {" "}
                            {props.k.componentsCollection.items[3].cta2.linkTitle}
                        </button>
                    )}
                    content={() => componentRef.current}
                />
            </div>


        </div>
    );


};
export default connect(mapStateToProps)(SuccessMessageComponent);

SuccessMessageComponent.propTypes = {
    k: PropTypes.object.isRequired,
};
export {SuccessMessageComponent, mapStateToProps};
