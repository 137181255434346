import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import validations from "../helper/validations";
import InfoIcon from "../assets/images/Icon feather-alert-circle.svg";
import crossIcon from "../assets/images/Cross-icon.svg";
import InputGroup from "react-bootstrap/InputGroup";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/styles/components/formInputFields.scss";
import PropTypes from "prop-types";

const FormCommon = (props) => {
  // Setting state variables
  const [err, seterr] = useState([]);
  const [validate, setValidate] = useState(props.shouldValidate);

  const onchange = (val, e) => {
    props.onChange(val, e);

    if (validate && props.validation === "onchange") {
      seterr(
        validations[props.controlId] ? validations[props.controlId](val) : []
      );
    } else {
      seterr("");
    }
  };

  const onblur = (e) => {
    if (props.blurHandler) props.blurHandler(e);
    if (validate && props.validation === "onblur") {
      seterr(
        validations[props.controlId]
          ? validations[props.controlId](e.target.value)
          : []
      );
    }
  };

  // Start validation on click of next or submit button
  useEffect(() => {
    if (validate || props.shouldValidate) {
      if (props.shouldValidate) setValidate(true);
      seterr(
        validations[props.controlId]
          ? validations[props.controlId](props.value)
          : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlId, validate, props.shouldValidate]);

  // Clear the field on click of cross icon
  const clearField = (e) => {
    e.target.parentElement.previousSibling.value = "";
    props.onChange("");
  };

  return (
    <Form.Group className="bank-form-group">
      {props.label ? (
        // Setting the label for the input field
        <Form.Label className="form-label-design" htmlFor={props.controlId}>
          {props.label}
        </Form.Label>
      ) : null}
      <InputGroup className={props.className}>
        {/*Displaying Radio buttons input field */}
        {props.type === "radio" ? (
          <fieldset>
            <legend className="radio-buttons-legend">{props.legend}</legend>
            {props.options.map((option, i) => {
              return (
                <Form.Check
                  inline
                  className="radio-buttons-label"
                  // defaultChecked={i === props.defaultChecked ? true : false}
                  checked={i === props.defaultChecked ? true : false}
                  key={i}
                  id={option.value}
                  type="radio"
                  onChange={(e) => props.onChange(e.target.id)}
                  name={props.legend}
                  label={option.label}
                  aria-required="true"
                />
              );
            })}
            {/* Display error message after validation for radio buttons */}
            {err.length > 0 ? (
              <div className="error-feedback-message">
                <img src={InfoIcon} alt="" />
                <span className="invalid-feedback-text">
                  {" "}
                  {err.length === 0 ? "" : `  ${err[0]}`}
                </span>
              </div>
            ) : null}
          </fieldset>
        ) : (
          <>
            {/* Displaying text input field */}
            <Form.Control
              className={
                props.as === "select"
                  ? "form-input-design select-down-arrow"
                  : `form-input-design `
              }
              id={props.controlId}
              type={props.type}
              placeholder={props.placeholder}
              onChange={(e) => onchange(e.target.value, e)}
              isInvalid={err.length === 0 ? false : true}
              {...props.dataAttr}
              onFocus={props.focusHandler}
              onBlur={onblur}
              onKeyDown={props.keyDownHandler}
              aria-required="true"
              maxLength={props.maxLength}
              as={props.as}
              autoComplete="cc-csc"
              onKeyPress={props.keyPressHandler}
              onClick={props.clickHandler}
              onMouseDown={props.mouseDownHandler}
              onTouchStart={props.touchStartHandler}
              onTouchMove={props.touchMoveHandler}
              // onTouchLeave={props.touchLeaveHandler}
              onTouchEnd={props.touchEndHandler}
              onTouchCancel={props.touchCancelHandler}
              onDrag={props.dragHandler}
              onDragStart={props.dragStartHandler}
              onDragEnd={props.dragEndHandler}
              // onGestureStart={props.GestureStartHandler}
              // onGestureEnd={props.GestureEndHandler}
              // onGestureChange={props.GestureChangeHandler}
              onInput={props.inputHandler}
            >
              {/* Displaying dropdown options from dropdown input fields  */}
              {props.options
                ? props.options.map((option, i) => (
                    <option
                      key={i}
                      value={option.Code}
                      selected={option.default}
                    >
                      {`${option.Name} (${option.Code})`}
                    </option>
                  ))
                : null}
            </Form.Control>
          </>
        )}

        {err.length === 0 || props.type === "radio" ? null : (
          <InputGroup.Append onClick={clearField}>
            <img src={crossIcon} alt="" />
          </InputGroup.Append>
        )}
        {/* Display error message after validation for text input fields */}
        <Form.Control.Feedback type="invalid">
          <img src={InfoIcon} alt="" />
          <span className="invalid-feedback-text">
            {err.length === 0 ? "" : `  ${err[0]}`}
          </span>
        </Form.Control.Feedback>
      </InputGroup>
      <div className="help-text">{props.helpText}</div>
    </Form.Group>
  );
};

// Check props types
FormCommon.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  options: PropTypes.array,
  as: PropTypes.string,
  legend: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
};

export default FormCommon;
