import { call, put, takeLatest } from "redux-saga/effects";

import {
  NAVIGATION_DATA,
  recieveNavigationData,
} from "../actions/navigationAction";

import { fetchNavigationData } from "./api/navigationApi";

//Calling navigation contentful data
export function* getNavigationApiData(action) {
  try {
    const data = yield call(fetchNavigationData, action.data);
    yield put(recieveNavigationData(data));
  } catch (e) {
    yield put(recieveNavigationData("error"));
  }
}

// Navigation Saga
export function* navigationSaga() {
  yield takeLatest(NAVIGATION_DATA, getNavigationApiData);
}
