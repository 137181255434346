import query from "../../queries/formQuery";
import axios from "axios";

export const fetchFormData = async (langid) => {
  // Setting headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      process.env.REACT_APP_PREVIEW === "true"
        ? process.env.REACT_APP_PREVIEW_ACCESS_TOKEN
        : process.env.REACT_APP_DELIVERY_ACCESS_TOKEN
    }`,
  };

  //Api call to fetch contentfull data
  const response = await axios({
    url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_SPACE_ID}/${process.env.REACT_APP_ENIVIRONMENT}`,
    method: "post",
    headers: headers,
    data: {
      query,
      variables: {
        langid: langid,
        preview: process.env.REACT_APP_PREVIEW === "true" ? true : false,
      },
    },
  });
  return response.data.data.standardPageCollection.items[0];
};
