import store from "../redux/store";

const validate = {
  // Firstname validation
  firstName: (val) => {
    const state = store.getState();
    var err = [];
    if (val) {
    } else {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_first_name_required"
        ]
      );
    }
    return err;
  },

  // Lastname validation
  lastName: (val) => {
    const state = store.getState();
    var err = [];
    if (val) {
    } else {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_last_name_required"
        ]
      );
    }
    return err;
  },
  // Policy number validation
  policyNumber: (val) => {
    const state = store.getState();
    var err = [];
    if (val) {
      var re = /^[AP]\d{9}$/;

      if (!re.test(val)) {
        err.push(
          state.validationData.messageCollection[" policy_number_digits"]
        );
      }
    } else {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_policy_number_required"
        ]
      );
    }
    return err;
  },

  // Phone number validation
  phoneNumber: (val) => {
    const state = store.getState();
    var err = [];
    val = val.replace(/-/g, "");
    if (val) {
      if (val.length !== 10 || isNaN(parseInt(val)) || !isFinite(val)) {
        err.push(
          state.validationData.messageCollection[
            "broker_payment_invalid_phone_number"
          ]
        );
      }
    } else {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_phone_number_required"
        ]
      );
    }
    return err;
  },

  // Policy amount validation
  policyAmount: (val) => {
    const state = store.getState();
    var err = [];
    val = val.replace("$", "");
    val = val.split(",").join("");
    if (val) {
      if (val == 0) {
        err.push(
          state.validationData.messageCollection[
            "broker_payment_amount_cannot_be_zero"
          ]
        );
      } else if (
        parseFloat(val) > parseFloat(process.env.REACT_APP_MAX_AMOUNT)
      ) {
        err.push(
          state.validationData.messageCollection["payment_max_limit_amount"]
        );
      } else if (isNaN(parseInt(val)) || !isFinite(val)) {
        err.push(
          state.validationData.messageCollection[
            "broker_payment_amount_should_be_in_digits"
          ]
        );
      }
    } else {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_policy_amount_required"
        ]
      );
    }
    return err;
  },

  // Insurance type validation
  insuranceType: (val) => {
    const state = store.getState();
    var err = [];
    if (val === "") {
      err.push(
        state.validationData.messageCollection[
          "broker_payment_validation_type_of_insurance_required"
        ]
      );
    }
    return err;
  },
};

export default validate;
