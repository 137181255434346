import React from "react";
import "../assets/styles/components/errorComponent.scss";
import { connect } from "react-redux";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Spinner from "../common/spinner";
import { useHistory } from "react-router-dom";
import { ROUTE, LANGID } from ".././constants/enums";
import InternalServerError from "../assets/images/500 Error.png";

// Mapping redux state to component props
const mapStateToProps = (state) => ({
  k: state.errorScreenData,
});

// Mapping dispatch to component props
const mapDispatchToProps = (dispatch) => ({
  requestErrorData: (data) => dispatch({ type: "ERROR_DATA", data }),
});

const ErrorScreen = (props) => {
  const { requestErrorData } = props;
  let history = useHistory();

  // Setting url for respective error and dispatching action to fetch contentful data
  React.useEffect(() => {
    if (!props.flag) {
      props.history.push(props.status);
    }

    if (props.k.componentsCollection === undefined)
      requestErrorData(LANGID.ENGLISH);
  }, [
    props.flag,
    props.status,
    props.history,
    requestErrorData,
    props.k.componentsCollection,
  ]);

  if (!props.k.componentsCollection & (props.k !== "error")) {
    // Displaing loader while fetching contentful data
    return <Spinner />;
  } else {
    // displaying error image if contentful data is not returned
    if (props.k === "error") {
      return <img src={InternalServerError} alt="" />;
    }
  }

  // Mapping respective error assets for respective error codes
  var getValue = {};
  var error = props.status.replace("/", "");
  var pageNotFound = ROUTE.PAGE_NOT_FOUND.replace("/", "");
  props.k.componentsCollection.items.map(
    (i) =>
      (getValue[i.errorType[0]] = [
        i.title,
        i.description,
        i.primaryImg,
        i.errorType,
        i.cta1Title,
        i.cta2Title,
        i.cta1Link,
        i.cta2Link,
      ])
  );

  var title = getValue[error][0];
  var description = getValue[error][1];
  var screen = getValue[error][2]?.url;
  var cta1Title = getValue[error][4];
  var cta2Title = getValue[error][5];
  var cta1Link = getValue[error][6];
  var cta2Link = getValue[error][7];

  document.title = error;

  // Navigating to previos page on click of previous button
  const goBack = () => {
    if (error === pageNotFound) {
      history.go(-3);
    } else {
      history.go(-3);
    }
  };

  // Navigating to home page on click of home button
  const goHome = () => {
    props.history.push(ROUTE.HOME);
  };
  return (
    <div
      id="error-page"
      className={
        props.status === "/404" ? "error-component-404" : "error-component"
      }
    >
      <img
        src={screen}
        alt=""
        className={props.status === "/404" ? "image-404" : ""}
      />
      <div
        className={
          props.status === "/404" ? "error-heading-404" : "error-heading"
        }
      >
        <span className="error-text">{title}​​​​​</span>
      </div>
      {/* rendering rich text from contentful */}
      <div className={props.status === "/404" ? "info-404" : "info"}>
        {documentToReactComponents(description.json)}
      </div>

      <div
        className={
          props.status === "/404"
            ? "button-styling-container-404"
            : "button-styling-container"
        }
      >
        {cta1Title ? (
          <button
            className={
              props.status === "/404"
                ? "update-form-button-404"
                : "update-form-button"
            }
            onClick={cta1Link === "/" ? goHome : goBack}
            id="button1"
          >
            {cta1Title}
          </button>
        ) : null}
        {cta2Title ? (
          <button
            className={
              props.status === "/404"
                ? "cancel-form-button-404"
                : "cancel-form-button"
            }
            onClick={cta2Link === "/" ? goHome : goBack}
            id="button2"
          >
            {cta2Title}
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);

export { ErrorScreen };
