// Declaring actions type
export const ERROR_DATA = "ERROR_DATA";
export const RECEIVE_ERROR_DATA = "RECEIVE_ERROR_DATA";

// Declaring actions
export const requestErrorData = (data) => ({ type: ERROR_DATA, data });
export const recieveErrorData = (data) => ({
  type: RECEIVE_ERROR_DATA,
  data,
});
