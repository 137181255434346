// Declaring actions type
export const MAINTENANCE_ERROR_DATA = "MAINTENANCE_ERROR_DATA";
export const RECEIVE_MAINTENANCE_ERROR_DATA = "RECEIVE_MAINTENANCE_ERROR_DATA";

// Declaring actions
export const requestMaintenanceErrorData = (data) => ({
  type: MAINTENANCE_ERROR_DATA,
  data,
});
export const recieveMaintenanceErrorData = (data) => ({
  type: RECEIVE_MAINTENANCE_ERROR_DATA,
  data,
});
