import React from "react";
import "../assets/styles/components/maintenancePage.scss";
import { connect } from "react-redux";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Spinner from "../common/spinner";
import InternalServerError from "../assets/images/500 Error.png";

// mapping redux state to component props
const mapStateToProps = (state) => ({
  k: state.maintenanceData,
  raiseMaintenancePage: state.navigationData.raiseMaintenancePage,
});

// mapping dispatchers to component props
const mapDispatchToProps = (dispatch) => ({
  requestMaintenanceErrorData: (data) =>
    dispatch({ type: "MAINTENANCE_ERROR_DATA", data }),
});

const Maintenance = (props) => {
  const { requestMaintenanceErrorData } = props;
  document.title = "Maintenance";

  // setting url according to the flag
  React.useEffect(() => {
    
    if (props.raiseMaintenancePage === true) {    
      props.history.push("/maintenance");
    } else {
      props.history.push("/");
    }
  }, [props.raiseMaintenancePage, props.history]);

  // Dispatching action to fetch contentful data
  React.useEffect(() => {
    if (props.k.componentsCollection === undefined) {
      requestMaintenanceErrorData();
    }
  }, [requestMaintenanceErrorData, props.k.componentsCollection]);

  // setting url according to the flag
  // React.useEffect(() => {
  //   props.history.push("/maintenance");
  // }, [props.history]);

  // Display loader while fetching contentful data or error image if error occurs while fetching contentful data
  if (!props.k.componentsCollection & (props.k !== "error")) {
    return <Spinner />;
  } else {
    if (props.k === "error") {
      return <img src={InternalServerError} alt="" />;
    }

    var title = props?.k?.componentsCollection?.items[0]?.title;
    var description = props?.k?.componentsCollection?.items[0]?.description;
    var screen = props?.k?.componentsCollection?.items[0]?.primaryImg?.url;
    var alttxt = props?.k?.componentsCollection?.items[0]?.primaryImgAltText;

    return (
      <div id="maintenance-page" className="error-component">
        <img src={screen} alt={alttxt} />

        <div className="error-heading">
          <span className="error-text">{title}​​​​​</span>
        </div>
        {/* rendering rich text from contentful data  */}
        <div className="info">
          {documentToReactComponents(description.json)}
        </div>

        <div className="button-styling-container">
          {props?.k?.componentsCollection?.items[0]?.cta1Title ? (
            <button
              className="update-form-button"
              id="button1"
              onClick={() =>
                props.history.push(
                  props?.k?.componentsCollection?.items[0]?.cta1Link
                )
              }
            >
              {props?.k?.componentsCollection?.items[0]?.cta1Title}
            </button>
          ) : (
            <div></div>
          )}

          {props?.k?.componentsCollection?.items[0]?.cta2Title ? (
            <button
              className="cancel-form-button"
              id="button2"
              onClick={() =>
                props.history.push(
                  props?.k?.componentsCollection?.items[0]?.cta2Link
                )
              }
            >
              {props?.k?.componentsCollection?.items[0]?.cta2Title}
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
export { Maintenance, mapStateToProps, mapDispatchToProps };
