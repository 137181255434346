const query = `query ($langid: String, $preview: Boolean) {
  navigationGroupCollection(limit: 1, locale: $langid, preview: $preview, where: {slug: "navigation-online-broker-payment"}) {
    items {
      slug
      raiseMaintenancePage
      disableLanguageToggleButton
      notificationMessagesCollection(locale: $langid, preview: $preview) {
        items {
          key
          value
          popupMessage {
            json
          }
          type
        }
      }
      contentCollection(limit: 2, locale: $langid, preview: $preview) {
        items {
          ... on StandardHeader {
            companyLogo {
              title
              url
            }
            companyLogoAltText
            navigationLinksCollection(limit: 5, locale: $langid, preview: $preview) {
              items {
                linkTitle
                linkUrl
              }
            }
            labelsCollection(locale: $langid, preview: $preview) {
              items {
                labelName
                labelText
              }
            }
            ariaLabelsCollection(locale: $langid, preview: $preview) {
              items {
                labelName
                labelText
              }
            }
          }
          ... on StandardFooter {
            footerMainSectionsCollection(limit: 5, locale: $langid, preview: $preview) {
              items {
                ... on FooterSectionItems {
                  sectionTitle
                  sectionLink
                }
              }
            }
            accordianTitle
            accordianCopy {
              json
            }
            legalCopy {
              json
            }
          }
        }
      }
      sys {
        spaceId
        environmentId
        publishedAt
      }
    }
  }
}`;

export default query;
