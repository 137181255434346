import React from "react";
import "../assets/styles/components/failureForms.scss";
import { ROUTE } from "../constants/enums";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../common/spinner";
const mapStateToProps = (state) => ({ k: state.formData });

const FailureMessageComponent = (props) => {
  // Redirecting to 404 page if directly access using url
  if (props.history.location.state === undefined) {
    props.history.push("404");
  }

  // Redirecting to internal server error page for contentful error
  if (props.k === "error") {
    props.history.push(ROUTE.INTERNAL_ERROR, { state: "500" });
    return <Spinner />;
  }

  // mapping images from contentfull data
  var getImg = {};
  props?.k?.componentsCollection?.items[4]?.iconImageCollection.items?.map(
    (i) => (getImg[i.key] = i.icon.url)
  );

  // mapping labels from contentfull data
  var getLabel = {};
  props?.k?.ariaLabelsCollection?.items?.map(
    (i) => (getLabel[i.labelName] = i.labelText)
  );

  return (
    <>
      <div id="payment-failed" className="failure-msg">
        <div className="failure-msg-container">
          <h1 className="payment-failed-text"> {getLabel["payment_failed"]}</h1>
          <img src={getImg["alert_icon"]} height="70" width="70" alt=""></img>
          <div className="failure-msg-heading">
            {props?.history?.state
              ? props?.history?.state
              : props.k.componentsCollection.items[4].headingText}
          </div>
          <button
            className="failure-msg-button"
            onClick={() => {
              props.history.push(ROUTE.HOME);
            }}
            id="another-payment-button"
          >
            {props.k.componentsCollection.items[4].cta1.linkTitle}
          </button>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps)(FailureMessageComponent);

FailureMessageComponent.propTypes = {
  k: PropTypes.object.isRequired,
};
export { FailureMessageComponent, mapStateToProps };
