import React from "react";
import { Form } from "react-bootstrap";
import "../assets/styles/components/formInputFields.scss";
import "../assets/styles/components/creditCardForms.scss";
import InputField from "../common/formInputFields";
import validations from "../helper/validations";
import { ROUTE, POLICY_TYPE } from "../constants/enums";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SeoMetadata from "../common/seoMetadata";
import Spinner from "../common/spinner";
import axios from "axios";

const mapStateToProps = (state) => ({
  k: state.formData,
  validationData: state.validationData,
});

const CreditCardForms = (props) => {
  //initializing state variables
  const [shouldValidate, setvalidator] = React.useState(false);
  const [policyNumber, setpolicyNumber] = React.useState("");
  const [policyAmount, setpolicyAmount] = React.useState("$");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [insuranceType, setInsuranceType] = React.useState(
    POLICY_TYPE.AUTOMOBILE
  );
  const [error, setError] = React.useState([]);
  var Eref = React.useRef();

  var prePointerPosition = 0;

  //to set focus on field level if validations returns error
  React.useEffect(() => {
    if (error.length > 0) {
      Eref.current.childNodes[1].childNodes[1].focus();
    }
  }, [error]);

  // to set the values in the form fields if the previous button is clicked on credit card payment page
  React.useEffect(() => {
    const getPolicyDetails = async () => {
      try {
        const restoken = await axios(process.env.REACT_APP_AUTH);
        const res = await axios.post(process.env.REACT_APP_GET_POLICY_DETAILS, {
          _csrf: restoken.data.csrfToken,
          path: "home",
        });
        if (res.status === 200) {
          if (res.data.data === undefined) return;

          document.getElementById("policyNumber").value =
            res.data.data.policyNumber;
          setpolicyNumber(res.data.data.policyNumber);

          document.getElementById("policyAmount").value =
            "$" + res.data.data.policyAmount.replace("$", "");
          setpolicyAmount("$" + res.data.data.policyAmount.replace("$", ""));

          document.getElementById("firstName").value = res.data.data.firstName;
          setFirstName(res.data.data.firstName);

          document.getElementById("lastName").value = res.data.data.lastName;
          setLastName(res.data.data.lastName);

          document.getElementById("phoneNumber").value =
            res.data.data.phoneNumber;
          setPhoneNumber(res.data.data.phoneNumber);

          document.getElementById("phoneNumber").value =
            res.data.data.phoneNumber;

          setInsuranceType(res.data.data.insuranceType);
        }
      } catch (error) {
        props.history.push(ROUTE.INTERNAL_ERROR, { state: "500" });
      }
    };

    if (document.getElementById("policyAmount"))
      document.getElementById("policyAmount").value = "$";
    if (document.getElementById("policyNumber")) {
      if (props.validNavigation) {
        props.setValidNavigation(false);
        getPolicyDetails();
      } else {
        axios.post(process.env.REACT_APP_DESTROY_SESSION, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Display internal server error page if we dont get the data from contentful
  if (props.k === "error" || props.validationData === "error") {
    props.history.push(ROUTE.INTERNAL_ERROR, { state: "500" });
    return <Spinner />;
  }

  // to map labels from contentfull data
  var getValue = {};
  props?.k?.labelsCollection?.items?.map(
    (i) => (getValue[i.labelName] = i.labelText)
  );

  // Map error messages from contentfull data
  var getMessage = {};
  props?.k?.messagesCollection?.items.map((i) => (getMessage[i.key] = i.value));

  const checkCursor = (e) => {
    const postPointerPosition = e.target.selectionStart;

    if (postPointerPosition === 0) {
      if (prePointerPosition === 0) {
        prePointerPosition = policyNumber.length;
      }
      e.preventDefault();
      let ctrl = e.target;

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(1, 1);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", 1);
        range.moveStart("character", 1);
        range.select();
      }
    }
  };

  // Setting initial 1st policy number alphabet based on insurance type on policy number field focus
  const policyNumberFocusHandler = () => {
    if (policyNumber === "") {
      if (insuranceType === POLICY_TYPE.AUTOMOBILE) {
        setpolicyNumber("A");
        document.getElementById("policyNumber").value = "A";
      } else {
        setpolicyNumber("P");
        document.getElementById("policyNumber").value = "P";
      }
    }
  };

  // Display policy number placeholder when no value is entered in policy number field
  const policyNumberBlurHandler = () => {
    if (policyNumber === "A" || policyNumber === "P") {
      setpolicyNumber("");
      document.getElementById("policyNumber").value = "";
    }
  };

  const policyNumberClickHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberTouchStartHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberTouchMoveHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberTouchEndHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberTouchCancelHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberTouchLeaveHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberDragHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberDragEndHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberDragStartHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberGestureStartHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberGestureEndHandler = (e) => {
    checkCursor(e);
  };

  const phoneNumberGestureChangeHandler = (e) => {
    checkCursor(e);
  };

  // To avoid removing or moving pointer before the 1st letter of policy number field which is set intially
  const policyNumberKeyDownHandler = (e) => {
    let position = e.target.selectionStart;

    if (
      (e.key === "ArrowUp" || e.key === "ArrowLeft") &&
      (position === 1 || position === 0)
    ) {
      e.preventDefault();

      checkCursor(e);
    }

    if (
      (e.target.value === "A" || e.target.value === "P") &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      e.preventDefault();
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      let ctrl = e.target;

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(1, 1);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        let range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", 1);
        range.moveStart("character", 1);
        range.select();
      }
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      let ctrl = e.target;

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(policyNumber.length, policyNumber.length);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        let range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", policyNumber.length);
        range.moveStart("character", policyNumber.length);
        range.select();
      }
    }
  };

  // Setting policy number state variable on entering the policy number
  const policyNumberChangeHandler = (val, e) => {
    e.preventDefault();
    let check = val.replace("A", "").replace("P", "");
    if (check.match(/[^0-9]/g)) {
      let position = e.target.selectionStart;
      if (insuranceType === POLICY_TYPE.AUTOMOBILE) {
        document.getElementById("policyNumber").value =
          "A" + val.replace(/[^0-9]/g, "");
      } else {
        document.getElementById("policyNumber").value =
          "P" + val.replace(/[^0-9]/g, "");
      }
      e.target.setSelectionRange(position - 1, position - 1);
      return;
    }

    setpolicyNumber(val);
    if (val[0] !== "P" && val[0] !== "A") {
      let position = e.target.selectionStart;

      if (position === 1) {
        position = 2;
      }

      if (position === 0) {
        position = 1;
      }

      val = val.replace("A", "");
      val = val.replace("P", "");
      if (insuranceType === POLICY_TYPE.AUTOMOBILE) {
        setpolicyNumber("A" + val);
        document.getElementById("policyNumber").value = "A" + val;
      } else {
        setpolicyNumber("P" + val);
        document.getElementById("policyNumber").value = "P" + val;
      }
      e.target.setSelectionRange(position, position);
    }
  };

  // To allow only digits in policy number field
  const policyNumberKeyPressHandler = (e) => {
    if (e.key.match(/[^0-9]/g)) {
      e.preventDefault();
    }
  };

  // To set insurance type state variable on selecting the insurance type
  const insuranceTypeChangeHandler = (val, e) => {
    setInsuranceType(val);
    setpolicyNumber("");
    document.getElementById("policyNumber").value = "";
  };

  // To allow only digits in phone number field
  const phoneNumberKeyPressHandler = (e) => {
    if (e.key.match(/[^0-9]/g)) {
      e.preventDefault();
    }
  };

  // To set phone number state variable on entering phone number and auto formating it to (xxx-xxx-xxxx)
  const phoneNumberChangeHandler = (val, e) => {
    let position = e.target.selectionStart;

    val = val.replace(/-/g, "");
    if (val.length > 6) {
      if (val.length >= phoneNumber.length) position = position + 2;
      val = val.substr(0, 3) + "-" + val.substr(3, 3) + "-" + val.substr(6);
    } else if (val.length > 3) {
      if (val.length > phoneNumber.length) position = position + 2;
      val = val.substr(0, 3) + "-" + val.substr(3);
    }
    setPhoneNumber(val);
    document.getElementById("phoneNumber").value = val;

    let ctrl = document.getElementById("phoneNumber");

    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(position, position);

      // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd("character", position);
      range.moveStart("character", position);
      range.select();
    }
  };

  const amountClickHandler = (e) => {
    checkCursor(e);
  };

  // To format policy amount on policy amount field blur
  const amountBlurHandler = () => {
    let amount = policyAmount.replace("$", "");
    amount = amount.split(",").join("");
    const decimalValue = parseFloat(amount).toFixed(2);

    if (decimalValue !== "NaN") {
      let maskedAmount = decimalValue
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setpolicyAmount("$" + maskedAmount);
      document.getElementById("policyAmount").value = "$" + maskedAmount;
    }
  };

  // Setting policy amount state variable on entering of policy amount
  const amountChangeHandler = (val, e) => {
    let check = val.replace("$", "");

    if (check.match(/[^0-9.,]/g)) {
      let position = e.target.selectionStart;

      document.getElementById("policyAmount").value =
        "$" + val.replace(/[^0-9.,]/g, "");
      e.target.setSelectionRange(position - 1, position - 1);
      return;
    }

    setpolicyAmount(val);

    if (val[0] !== "$") {
      let position = e.target.selectionStart;

      if (position === 1) {
        position = 2;
      }
      if (position === 0) {
        position = 1;
      }
      val = val.replace("$", "");
      document.getElementById("policyAmount").value = "$" + val;
      setpolicyAmount("$" + val);
      e.target.setSelectionRange(position, position);
    }

    if (e.target.selectionStart === 0) {
      e.target.setSelectionRange(1, 1);
    }
  };
  // To avoid removing $ sign from the policy amount form field
  const amountKeyDownHandler = (e) => {
    if (e.code === "Period" && e.target.value.indexOf(".") >= 0) {
      e.preventDefault();
    }

    let position = e.target.selectionStart;

    if (e.key === "ArrowLeft" && (position === 1 || position === 0)) {
      e.preventDefault();

      let ctrl = document.getElementById("policyAmount");

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(1, 1);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", 1);
        range.moveStart("character", 1);
        range.select();
      }
    }

    if (
      e.target.value === "$" &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      e.preventDefault();
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      let ctrl = document.getElementById("policyAmount");

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(1, 1);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        let range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", 1);
        range.moveStart("character", 1);
        range.select();
      }
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      let ctrl = document.getElementById("policyAmount");

      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(policyAmount.length, policyAmount.length);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        let range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", policyAmount.length);
        range.moveStart("character", policyAmount.length);
        range.select();
      }
    }
  };

  // To allow only digits, commas and single period in policy amount form field
  const amountKeyPressHandler = (e) => {
    if (e.key.match(/[^0-9.,]/g)) {
      // e.preventDefault();
    }
    if (e.code === "Period" && e.target.value.indexOf(".") >= 0) {
      e.preventDefault();
    }
  };

  const amountTouchStartHandler = (e) => {
    checkCursor(e);
  };

  const amountTouchMoveHandler = (e) => {
    checkCursor(e);
  };

  const amountTouchEndHandler = (e) => {
    checkCursor(e);
  };

  const amountTouchCancelHandler = (e) => {
    checkCursor(e);
  };

  const amountTouchLeaveHandler = (e) => {
    checkCursor(e);
  };

  // Setting respective common input field props
  const formdata = {
    policyNumber: {
      label: getValue["label_name_broker_payment_policy_number"],
      controlId: "policyNumber",
      value: policyNumber,
      onChange: policyNumberChangeHandler,
      keyDownHandler: policyNumberKeyDownHandler,
      type: "text",
      maxLength: 10,
      className: "form-input-width-35",
      as: "input",
      focusHandler: policyNumberFocusHandler,
      blurHandler: policyNumberBlurHandler,
      keyPressHandler: policyNumberKeyPressHandler,
      clickHandler: policyNumberClickHandler,
      setvalidator,
      validation: "onblur",
      touchStartHandler: phoneNumberTouchStartHandler,
      touchMoveHandler: phoneNumberTouchMoveHandler,
      touchEndHandler: phoneNumberTouchEndHandler,
      touchCancelHandler: phoneNumberTouchCancelHandler,
      touchLeaveHandler: phoneNumberTouchLeaveHandler,
      dragHandler: phoneNumberDragHandler,
      dragEndHandler: phoneNumberDragEndHandler,
      dragStartHandler: phoneNumberDragStartHandler,
      GestureStartHandler: phoneNumberGestureStartHandler,
      GestureEndHandler: phoneNumberGestureEndHandler,
      GestureChangeHandler: phoneNumberGestureChangeHandler,
    },

    insuranceType: {
      legend: getValue["broker_payment_type_of_insurance"],
      controlId: "insuranceType",
      value: insuranceType,
      onChange: insuranceTypeChangeHandler,
      type: "radio",
      as: "radio",
      defaultChecked: insuranceType === POLICY_TYPE.AUTOMOBILE ? 0 : 1,
      setvalidator,
      options: [
        {
          label: getValue["broker_payment_radio_button_auto"],
          value: POLICY_TYPE.AUTOMOBILE,
        },
        {
          label: getValue["broker_payment_radio_button_home"],
          value: POLICY_TYPE.PROPERTY,
        },
      ],
    },

    policyAmount: {
      label: getValue["label_name_policy_amount"],
      controlId: "policyAmount",
      value: policyAmount,
      onChange: amountChangeHandler,
      keyDownHandler: amountKeyDownHandler,
      keyPressHandler: amountKeyPressHandler,
      type: "text",
      maxLength: 20,
      className: "form-input-width-35",
      placeholder: getValue["help_text_label_policy_amount"],
      setvalidator,
      validation: "onchange",
      clickHandler: amountClickHandler,
      touchStartHandler: amountTouchStartHandler,
      touchMoveHandler: amountTouchMoveHandler,
      touchEndHandler: amountTouchEndHandler,
      touchCancelHandler: amountTouchCancelHandler,
      touchLeaveHandler: amountTouchLeaveHandler,
    },
    firstName: {
      label: getValue["label_name_policy_holder_first_name"],
      controlId: "firstName",
      value: firstName,
      onChange: setFirstName,
      type: "text",
      className: "form-input-width-80",
      maxLength: 30,
      setvalidator,
      validation: "onchange",
    },
    lastName: {
      label: getValue["label_name_policy_holder_last_name"],
      controlId: "lastName",
      value: lastName,
      onChange: setLastName,
      type: "text",
      className: "form-input-width-80",
      maxLength: 30,
      setvalidator,
      validation: "onchange",
    },
    phoneNumber: {
      label: getValue["label_name_policy_holder_phone_number"],
      controlId: "phoneNumber",
      value: phoneNumber,
      onChange: phoneNumberChangeHandler,
      keyPressHandler: phoneNumberKeyPressHandler,
      type: "text",
      className: "form-input-width-35",
      maxLength: 12,
      helpText: getValue["help_text_label_policy_holder_phone_number"],
      setvalidator,
      validation: "onblur",
    },
  };

  const cacheDate = async () => {
    try {
      const restoken = await axios(process.env.REACT_APP_AUTH);
      const res = await axios.post(process.env.REACT_APP_SET_POLICY_DETAILS, {
        _csrf: restoken.data.csrfToken,
        policyNumber,
        policyAmount,
        phoneNumber,
        lastName,
        firstName,
        insuranceType,
      });

      if (res.status === 200) {
        props.setValidNavigation(true);
        props.history.push(ROUTE.PAYMENT);
      }
    } catch (error) {
      props.history.push(ROUTE.INTERNAL_ERROR, { state: "500" });
    }
  };

  // To perform validation for respective form fields on click of next button
  const handleClick = (e) => {
    var err = [];
    err = [
      ...err,
      ...validations["insuranceType"](insuranceType).map((msg) => ({
        msg: msg,
        href: "insuranceType",
      })),
    ];
    err = [
      ...err,
      ...validations["policyNumber"](policyNumber).map((msg) => ({
        msg: msg,
        href: "policyNumber",
      })),
    ];
    err = [
      ...err,
      ...validations["policyAmount"](policyAmount).map((msg) => ({
        msg: msg,
        href: "policyAmount",
      })),
    ];

    err = [
      ...err,
      ...validations["firstName"](firstName).map((msg) => ({
        msg: msg,
        href: "firstName",
      })),
    ];

    err = [
      ...err,
      ...validations["lastName"](lastName).map((msg) => ({
        msg: msg,
        href: "lastName",
      })),
    ];

    err = [
      ...err,
      ...validations["phoneNumber"](phoneNumber).map((msg) => ({
        msg: msg,
        href: "phoneNumber",
      })),
    ];

    setvalidator(e);
    setError(err);
    if (err.length === 0) {
      cacheDate();
    }
  };

  return (
    <>
      {/* setting the seo metting data recieved from contentful */}
      <SeoMetadata seo={props?.k?.seoMetadata} />
      <div id="policy-details-form" className="credit-card-container-fluid">
        <div className="heading-image-container">
          <h1 className="credit-card-main-heading">
            {props.k.componentsCollection.items[0].headingText}
          </h1>
          <img
            className="car-image"
            src={props.k.componentsCollection.items[0].backGroundImg.url}
            alt=""
            width="260"
            height="40"
          ></img>
        </div>
        {/* displaying field level error messages after performing validation for respective fields */}
        {error.length !== 0 ? (
          <div role="alert">
            <ul
              className="error"
              id="page_error_block"
              ref={Eref}
              tabIndex="-1"
              aria-atomic="true"
              aria-live="assertive"
            >
              <span className="error-count">
                {getMessage["broker_payment_policy_info_error"].replace(
                  "<NumberOfErrors>",
                  error.length
                )}
              </span>
              {error.map((value, index) => (
                <li key={index}>
                  {`Error ${index + 1}: `}
                  <a
                    href="#main"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById(value.href).focus();
                    }}
                  >{` ${value.msg}`}</a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="credit-card-container">
          <div className="credit-card-para">
            {props.k.componentsCollection.items[0].title}
          </div>

          <h2 className="credit-card-policy">
            {getValue["broker_payment_policy_information"]}
          </h2>

          <Form>
            {/* insurance type radio button  */}
            <div id="insuranceType">
              <InputField
                {...formdata["insuranceType"]}
                shouldValidate={shouldValidate}
              />
            </div>

            {/* policy number field  */}
            <InputField
              className="form-input-width"
              {...formdata["policyNumber"]}
              shouldValidate={shouldValidate}
              placeholder={
                insuranceType === POLICY_TYPE.AUTOMOBILE
                  ? getValue["help_text_label_auto_policy_number"]
                  : getValue["help_text_label_home_policy_number"]
              }
            />

            {/* policy number field  */}
            <InputField
              {...formdata["policyAmount"]}
              shouldValidate={shouldValidate}
              blurHandler={amountBlurHandler}
            />
            <div className="credit-card-form-container">
              <h2 className="credit-card-heading">
                {getValue["policy_holder_information"]}
              </h2>

              {/* first name field */}
              <InputField
                {...formdata["firstName"]}
                shouldValidate={shouldValidate}
              />

              {/* last name field */}
              <InputField
                {...formdata["lastName"]}
                shouldValidate={shouldValidate}
              />

              {/* phone number field  */}
              <InputField
                {...formdata["phoneNumber"]}
                shouldValidate={shouldValidate}
              />
            </div>
          </Form>
        </div>
        <div className="credit-button-container">
          {/* next button */}
          <button
            className="credit-button"
            variant="primary"
            type="submit"
            onClick={handleClick}
            id="next-button"
          >
            {props.k.componentsCollection.items[0].cta1.linkTitle}
          </button>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps)(CreditCardForms);

CreditCardForms.propTypes = {
  k: PropTypes.object.isRequired,
};
export { CreditCardForms };
