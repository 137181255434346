import { call, put, takeLatest } from "redux-saga/effects";

import { FORM_DATA, recieveFormData } from "../actions/formAction";

import { fetchFormData } from "./api/formApi";

// Calling form data api
export function* getFormApiData(action) {
  try {
    const data = yield call(fetchFormData, action.data);
    yield put(recieveFormData(data));
  } catch (e) {
    yield put(recieveFormData("error"));
  }
}

// Error screen Saga
export function* formSaga() {
  yield takeLatest(FORM_DATA, getFormApiData);
}
