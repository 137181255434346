import React from "react";
import { Navbar } from "react-bootstrap";
import "../assets/styles/components/header.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom"
import {ROUTE} from "../constants/enums"

const Header = (props) => {
const history=useHistory()

if(history.location.pathname===ROUTE.HEALTHCHECK) return null
  return (
    <header id="header" role="banner">
      <Navbar expand="md" sticky="top">
        {/* Display logo */}
        <div id="logo-link" className="navbar-brand">
          <img
            src={props.contentfulData.companyLogo.url}
            width="217"
            height="90"
            className="d-inline-block align-top"
            alt={props.contentfulData.companyLogoAltText}
          />
        </div>
        <div className="footer-links">
          {/* Display header links */}
          {props.contentfulData.navigationLinksCollection.items.map((link) => {
            return (
              <Link
                key={link.linkTitle}
                to={link.linkUrl}
                className="route-link"
              >
                {link.linkTitle}
              </Link>
            );
          })}
        </div>
      </Navbar>
    </header>
  );
};

export default Header;

Header.propTypes = {
  contentfulData: PropTypes.object.isRequired,
};
