import { combineReducers } from "redux";
import navigationData from "./navigationReducer";
import formData from "./formReducer";
import validationData from "./validationReducer";
import errorScreenData from "./errorScreenReducer";
import maintenanceData from "./maintenanceReducer"

export default combineReducers({
  navigationData,
  formData,
  validationData,
  errorScreenData,
  maintenanceData,
  
});
