import { RECEIVE_NAVIGATION_DATA } from "../actions/navigationAction";

const navigationReducer = (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_NAVIGATION_DATA:
      return data;
    default:
      return state;
  }
};
export default navigationReducer;
