import "./App.css";
import "../src/assets/app.scss";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import FailureMessageComponent from "./components/failure";
import SuccessMessageComponent from "./components/success";
import CreditCardForms from "./components/creditcardForms";
import CreditPayment from "./components/CreditPayment";
import Header from "./common/header";
import Footer from "./common/footer";
import { ROUTE, LANGID } from "./constants/enums";
import { connect } from "react-redux";
import Spinner from "./common/spinner";
import ErrorComponent from "./components/errorComponent";
import AutoScrollTop from "./components/autoScrollTop";
import MaintenanceRouter from "./maintenanceRouter";
import MaintenancePage from "./common/maintenance";
import BrowserNotSupported from "./components/browserNotSupported";
import axios from "axios";
import healthcheck from "./components/healthcheck"


function App(props) {
  const [browserSupport, setbrowserSupport] = useState(false);
  const [validNavigation, setValidNavigation] = useState(false);

  const { requestNavigationData, requestFormData, requestValidationData } =
    props;

    const history=useHistory()
  // Check for internet explorer browser to display broswer not supported pop up
  React.useEffect(() => {
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    setbrowserSupport(isIE);

    // window.addEventListener("unload", function (e) {
    //   axios.post(process.env.REACT_APP_DESTROY_SESSION, {});
    // });
  }, []);

  // Dispatching actions to fetch contentful data
  React.useEffect(() => {
    requestNavigationData(LANGID.ENGLISH);
    requestFormData(LANGID.ENGLISH);
    requestValidationData(LANGID.ENGLISH);
  }, [requestNavigationData, requestFormData, requestValidationData]);

  // Display spinner while fetching navigation contentful data
  if (!props.navigationData.sys && props.navigationData !== "error") {
    return <Spinner />;
  }
  // Display spinner while fetching form contentful data
  if (!props.formData.sys && props.formData !== "error") {
    return <Spinner />;
  }
  // Display spinner while fetching validation contentful data
  if (
    !props.validationData.messageCollection &&
    props.validationData !== "error"
  ) {
    return <Spinner />;
  }

  // Display internal server page if error occurs while fetching navigation data
  if (props.navigationData === "error") {
    return <ErrorComponent status={ROUTE.INTERNAL_ERROR} flag={true} />;
  }


  // Router to display maintenance page
  if (props.raiseMaintenancePage === true) {
    return (
      <React.Fragment>
        <Router>
          <MaintenanceRouter />
        </Router>
      </React.Fragment>
    );
  } else {
    return (
      // Display normal homepage
      <React.Fragment>
        {/* Browser not supported pop up modal */}
        <BrowserNotSupported
          support={browserSupport}
          setbrowserSupport={setbrowserSupport}
          contentfulData={
            props.navigationData.notificationMessagesCollection.items[0]
          }
        />
        <Router>
          <AutoScrollTop />
          <Header
            contentfulData={props.navigationData.contentCollection.items[0]}
          />
          <div className="main-content" role="main">
            <Switch>
              {/* Route for homepage  */}
              <Route
                exact
                path={ROUTE.HOME}
                render={(props) => (
                  <CreditCardForms
                    history={props.history}
                    status={ROUTE.INTERNAL_ERROR}
                    setValidNavigation={setValidNavigation}
                    validNavigation={validNavigation}
                  />
                )}
                // component={CreditCardForms}
              />

              {/*  Route for payment success  */}
              <Route
                exact
                path={ROUTE.SUCCESS}
                component={SuccessMessageComponent}
              />

              {/*  Route for payment failure  */}
              <Route
                exact
                path={ROUTE.FAIL}
                component={FailureMessageComponent}
              />

              {/*  Route for credit card payment page  */}
              <Route
                exact
                path={ROUTE.PAYMENT}
                render={(props) => (
                  <CreditPayment
                    history={props.history}
                    status={ROUTE.INTERNAL_ERROR}
                    validNavigation={validNavigation}
                    setValidNavigation={setValidNavigation}
                  />
                )}
                // component={CreditPayment}
              />

              <Route
                exact
                path={ROUTE.HEALTHCHECK}
                component={healthcheck}
              />

              {/*  Route for internal server page */}
              <Route
                exact
                path={ROUTE.INTERNAL_ERROR}
                render={(props) => (
                  <ErrorComponent
                    history={props.history}
                    status={ROUTE.INTERNAL_ERROR}
                  />
                )}
              />

              {/*  Route for maintenance page  */}
              <Route
                exact
                path="/maintenance"
                render={(props) => (
                  <MaintenancePage
                    history={props.history}
                    status="/maintenance"
                  />
                )}
              />

              {/*  Route for 404 page not found  */}
              <Route
                exact
                path="*"
                render={(props) => (
                  <ErrorComponent
                    history={props.history}
                    status={ROUTE.PAGE_NOT_FOUND}
                  />
                )}
              />
            </Switch>
          </div>
          <Footer
          contentfulData={props.navigationData.contentCollection.items[1]}
        />
        </Router>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  navigationData: state.navigationData,
  validationData: state.validationData,
  formData: state.formData,
  raiseMaintenancePage: state.navigationData.raiseMaintenancePage,
});

const mapDispatchToProps = (dispatch) => ({
  requestNavigationData: (data) => dispatch({ type: "NAVIGATION_DATA", data }),
  requestFormData: (data) => dispatch({ type: "FORM_DATA", data }),
  requestValidationData: (data) => dispatch({ type: "VALIDATION_DATA", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

export { App, mapStateToProps, mapDispatchToProps };
