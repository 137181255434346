import { call, put, takeLatest } from "redux-saga/effects";

import {
  VALIDATION_DATA,
  recieveValidationData,
} from "../actions/validationAction";

import { fetchValidationData } from "./api/validationApi";

//Calling validation contentful data
export function* getValidationApiData(action) {
  try {
    const data = yield call(fetchValidationData, action.data);
    yield put(recieveValidationData(data));
  } catch (e) {
    yield put(recieveValidationData("error"));
  }
}

// Validation saga
export function* validationSaga() {
  yield takeLatest(VALIDATION_DATA, getValidationApiData);
}
