import React from "react";

export class PrintReceipt extends React.PureComponent {
    //making reciept for payment
    render() {
        return (
            <div id="print-reciept" className="container">
                <p>CAA Insurance Company</p>
                <p>Credit Card Payment Reciept</p>

                <div className="body-header">
                    <div className="approved">Approved</div>
                </div>
                <div className="print-body">
                    <table className="print-table">
                        <thead>
                        <tr>
                            <td className="print-data">Type</td>
                            <td>Purchase</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Order ID</td>
                            <td>{this.props.orderId}</td>
                        </tr>
                        <tr>
                            <td>Amount(CAD)</td>
                            <td>$ {this.props.amount}</td>
                        </tr>

                        <tr>
                            <td>Card Number</td>
                            <td>{this.props.cardNumber}</td>
                        </tr>
                        <tr>
                            <td>Card Type</td>
                            <td>{this.props.cardType}</td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>{this.props.date}</td>
                        </tr>
                        <tr>
                            <td>Policy Number</td>
                            <td>{this.props.policyNumber}</td>
                        </tr>
                        <tr>
                            <td>Customer Name</td>
                            <td>{this.props.customerName}</td>
                        </tr>

                        <tr>
                            <td>Authorization Code</td>
                            <td>{this.props.authorizationCode}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <span className="thanks">thank you for your payment</span>
                </div>
            </div>
        );
    }
}
